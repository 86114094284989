import React from 'react';
import * as styles from './GridTextProjectElement.module.css'
import { pojectInfos } from './../context/consts'
import Awards from './Awards';


const GridTextProjectElement = ({ projectName }) => {
    let { client, year, studio, description, hashtags, awards } = pojectInfos[projectName]

    awards = awards || {}

    return (
        <div className={styles.projectContainer}>
            <div className={styles.awardsContainer}>
                <Awards adi={awards.adi || false}
                    compasso={awards.compasso || false}
                    german={awards.german || false}
                    reddot={awards.reddot || false}
                    dyson={awards.dyson || false} />
            </div>
            <div>Client: <b>{client}</b></div>
            <div>Year: <b>{year}</b></div>
            {studio && <div>Design Studio Associated: <b>{studio}</b></div>}
            <p className={styles.description}>{description}</p>
            <div className={styles.hashtags}>{hashtags.map((h, i) => <i key={i}>{`#${h}`}&nbsp;</i>)}</div>
        </div>
    )
}

export default GridTextProjectElement